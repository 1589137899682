import React from 'react'
import {GrFacebook, GrInstagram, GrYoutube} from 'react-icons/gr';
import { Link } from 'gatsby';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container mx-auto h-full">
                <div className="flex h-full flex-wrap">
                    <div className="footer__col">
                        <h5>Visita la tienda:</h5>
                        <a href="https://www.neonail-espana.es/" target="_blank" rel="noopener noreferrer">
                            <img className="h-10" src={require("../../images/logo.png")} alt="" />
                        </a>
                    </div>
                    <div className="footer__col">
                        <h5>Siguénos:</h5>
                        <div className="footer__social">
                            <a href="https://www.facebook.com/NEONAILESPANA/" target="_blank" rel="noopener noreferrer">
                                <figure className="footer__ico">
                                    <GrFacebook />
                                </figure>
                            </a>
                            <a href="https://www.instagram.com/neonailespana/" target="_blank" rel="noopener noreferrer">
                                <figure className="footer__ico">
                                    <GrInstagram />
                                </figure>
                            </a>
                            <a href="https://www.youtube.com/channel/UCeF4X7BpJKzDaKiiRztga1A" target="_blank" rel="noopener noreferrer">
                                <figure className="footer__ico">
                                    <GrYoutube />
                                </figure>
                            </a>
                            <a href="https://www.tiktok.com/@neonailespana?lang=es" target="_blank" rel="noopener noreferrer">
                                <figure className="footer__ico">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="402.347" height="455.334" viewBox="0 0 402.347 455.334">
                                        <g id="Group_131" data-name="Group 131" transform="translate(-931.268 -211.158)">
                                            <g id="g12" transform="translate(931.268 211.158)">
                                            <path id="path2" d="M161.429,34.611V16.851a137.344,137.344,0,0,0-18.6-1.356C66.7,15.5,4.76,77.442,4.76,153.571A137.978,137.978,0,0,0,63.7,266.606a137.522,137.522,0,0,1-37.074-94c0-75.044,60.173-136.24,134.8-137.992" transform="translate(-4.76 144.678)" fill="#00f2ea" fill-rule="evenodd"/>
                                            <path id="path4" d="M65.8,381.207a63.088,63.088,0,0,0,62.937-60.695l.115-300.605h54.917A104.274,104.274,0,0,1,182,.875H107l-.125,300.616a62.92,62.92,0,0,1-92.179,53.436A62.958,62.958,0,0,0,65.8,381.207M286.358,121.941V105.234A103.692,103.692,0,0,1,229.532,88.34a104.4,104.4,0,0,0,56.836,33.6" transform="translate(94.12 -0.875)" fill="#00f2ea" fill-rule="evenodd"/>
                                            <path id="path6" d="M260.246,71.025A103.89,103.89,0,0,1,234.582,2.613h-20.1a104.547,104.547,0,0,0,45.761,68.412M74.658,218.757A63.029,63.029,0,0,0,45.4,337.622a62.957,62.957,0,0,1,51.121-99.833,61.957,61.957,0,0,1,18.6,2.92V164.132a137.342,137.342,0,0,0-18.6-1.356c-1.1,0-2.169.063-3.254.083v58.817a61.988,61.988,0,0,0-18.6-2.92" transform="translate(63.406 16.43)" fill="#ff004f" fill-rule="evenodd"/>
                                            <path id="path8" d="M331.676,11.935v58.3A178.4,178.4,0,0,1,227.316,36.672V189.107c0,76.129-61.925,138.065-138.054,138.065A137.225,137.225,0,0,1,10.14,302.143,137.668,137.668,0,0,0,111.121,346.2c76.129,0,138.065-61.936,138.065-138.054V55.715A178.4,178.4,0,0,0,353.545,89.274V14.251a104.22,104.22,0,0,1-21.869-2.326" transform="translate(48.803 109.131)" fill="#ff004f" fill-rule="evenodd"/>
                                            <path id="path10" d="M261.006,281.808V129.373a178.361,178.361,0,0,0,104.359,33.559v-58.3A104.4,104.4,0,0,1,308.54,71.025,104.547,104.547,0,0,1,262.779,2.613H207.862l-.115,300.605A62.931,62.931,0,0,1,93.7,337.622a63.026,63.026,0,0,1,29.252-118.865,61.733,61.733,0,0,1,18.6,2.92V162.859c-74.627,1.752-134.8,62.947-134.8,137.992a137.547,137.547,0,0,0,37.074,94,137.241,137.241,0,0,0,79.122,25.018c76.129,0,138.054-61.936,138.054-138.065" transform="translate(15.113 16.43)" fill-rule="evenodd"/>
                                            </g>
                                        </g>
                                    </svg>
                                </figure>
                            </a>
                        </div>
                    </div>
                    <div className="footer__col">
                        <h5>Página:</h5>
                        <ul className="footer__navigation">
                            <li><Link to ="/">Empezar</Link></li>
                            <li><Link to="https://www.neonail-espana.es/inspiraciones/">Galería de manicura</Link></li>
                            <li><Link to="/anadir-inspiranos">Añadir foto</Link></li>
                            <li><Link to="/abc-inspirate">Consultorio</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
