import React from "react"
import Header from "./layout/Header"
import Footer from "./layout/Footer"
import CookieConsent from "react-cookie-consent";

const Layout = ({ children }) => {
  return (
    <main>
      <Header />
      <section className="content">{children}</section>
      <Footer />
      <CookieConsent
          location="bottom"
          buttonText="Entendido"
          style={{ background: "rgba(0,0,0, .75)" }}
          >
        Nuestra página web utiliza las cookies. Visitando la página aceptas instalación de las cookies. Más información puedes encontrar en <a style={{textDecoration: "underline"}} href="https://www.neonail-espana.es/proteccion-de-datos.html" target="_blank">Protección de datos</a>.
      </CookieConsent>
    </main>
  )
}

export default Layout
